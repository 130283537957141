import type { IncomingMessage } from 'http'
import type {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
  NextPageContext,
} from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { type NextApiRequestCookies } from 'next/dist/server/api-utils'
import { type ParsedUrlQuery } from 'querystring'

import { fetchApi } from '@hozana/api/fetchApi'
import { addLangMeta } from '@hozana/api/functions'
import type { TQuery, TQueryData } from '@hozana/api/types'
import { fakeThunkDispatch } from '@hozana/redux/functions'
import type { TStore, TWrappedServeSideContext, TWrappedStaticContext } from '@hozana/redux/types'
import { Sentry } from '@hozana/tracking/sentry'

import { setTitle } from 'general/actions'
import { COOKIES } from 'general/managers/cookies/constants'
import { E2E_QUERY_KEY } from 'general/managers/debugger/constants'
import { type TTitle } from 'general/types'
import { COMMON_NAMESPACES, NAMESPACE, TLocale } from 'i18n/constants'
import { nextI18nextConfig } from 'i18n/next-i18next.config'
import type { TI18nextSSRConfig } from 'i18n/types'
import { API_PATHNAME } from 'routes/api'
import { PAGE } from 'routes/constants'

/**
 * Both createGetServerSideData and createGetStaticData take as parameter
 * a function that return an object of TServerApiQueries.
 *
 * The keys of this object define some props that can be passed to the page - or not,
 * depending on the associated getStrategy.
 */
export type TServerApiQueries<Key extends string | number | symbol = string> = Record<
  Key,
  {
    /** Query to fetch: A [TQuery object](types.ts). Ex: `getCommunitiesQuery()`. */
    query: TQuery
    /** No call to the API will be performed if this is true. */
    disabled?: boolean
    /**
     * If set to true, any 400 or 500 error in the query will display the correponding error page.
     */
    displayErrors?: boolean
    /**
     * How to get the data:
     * - 'store' - (default) in the redux store only,
     * - 'prop' - in the page props only,
     * - 'both' - both in the store and in the page props.
     */
    getStrategy?: 'store' | 'prop' | 'both'
    /**
     * If set to true, details about the query will be logged into the terminal console.
     */
    debug?: boolean
  }
>
export type TServerApiQueriesResult<AQ extends TServerApiQueries> = {
  [K in TKeysMatching<AQ, { getStrategy: 'prop' | 'both' }>]: TQueryData<AQ[K]['query']>
}

type TGetDataContext = TOptionalValues<NextPageContext, 'AppTree' | 'pathname' | 'query' | 'res' | 'req'> & {
  store: TStore
}

export const createGetInitialData =
  <AQ extends TServerApiQueries>(apiQueries: AQ) =>
  async ({ locale, query, req, store }: TGetDataContext): Promise<TServerApiQueriesResult<AQ>> => {
    const e2eDB =
      !!(
        req as IncomingMessage & {
          cookies: NextApiRequestCookies
        }
      )?.cookies?.[COOKIES.e2eDB] || query?.[E2E_QUERY_KEY] === 'true'

    const promises = (Object.entries(apiQueries) as TObjectEntries<typeof apiQueries>).map(async ([name, apiQuery]) => {
      try {
        const fetch = <Query extends TQuery>(queryToFetch: Query) =>
          !apiQuery.getStrategy || ['store', 'both'].includes(apiQuery.getStrategy)
            ? store.dispatch(fetchApi(queryToFetch, apiQuery.displayErrors, e2eDB))
            : fakeThunkDispatch(fetchApi(queryToFetch, apiQuery.displayErrors, e2eDB))

        const apiQueryResult = await fetch(addLangMeta(apiQuery.query, locale))

        if (apiQuery.debug) {
          console.log(
            `Fetching ${apiQuery.query.queryKey} (${apiQuery.query.url} endpoint) succeeded. Result:`,
            apiQueryResult,
          )
        }

        if (['prop', 'both'].includes(apiQuery.getStrategy)) {
          return { [name]: apiQueryResult }
        }
        return {}
      } catch (error) {
        Sentry.captureMessage('Fetch failed on server', 'error', {
          context: req ? 'server side fetch' : 'static fetch',
          queryKey: apiQuery.query.queryKey,
          url: apiQuery.query.url,
        })
        if (apiQuery.debug) {
          console.log(`Fetching ${apiQuery.query.queryKey} (${apiQuery.query.url} endpoint) failed:\n`, error)
        }

        if (apiQuery.displayErrors) {
          return Promise.resolve({ [name]: new Error('Not found') })
        }
        return {}
      }
    }) as Promise<TServerApiQueriesResult<AQ>>[]

    const promiseResults = await Promise.all(promises)

    return Object.assign({}, ...promiseResults)
  }

export type TCommonPageProps = {
  statusCode: number
  userAgent?: string
  locale: string
} & TI18nextSSRConfig

type TPromiseOrNotResult<PON> = PON extends Promise<any> ? Awaited<PON> : PON
type TGetGetServerPropsResultProps<R extends GetStaticPropsResult<any> | GetServerSidePropsResult<any>> = R extends {
  props: any
}
  ? R['props']
  : never
export type TGetGetServerPropsProps<G extends GetStaticProps<any>> = TGetGetServerPropsResultProps<
  TPromiseOrNotResult<ReturnType<G>>
>

export type TGetCommonPagePropsOptions = {
  /** An array of the required specific namespaces to display the page */
  namespaces?: NAMESPACE[]
}

/**
 * This function must be called for every page build on the server,
 * may it be by getInitialProps, getServerSideProps, or getStaticProps,
 * because it sets the server side global variables used in the code
 */
export const getCommonPageProps = async (
  ctx: NextPageContext | ((GetServerSidePropsContext | GetStaticPropsContext) & { pathname: PAGE }),
  { namespaces }: TGetCommonPagePropsOptions,
): Promise<TCommonPageProps> => {
  const { locale = 'fr' } = ctx
  const { req, res: { statusCode } = { statusCode: undefined } } =
    'req' in ctx ? ctx : { req: undefined, res: undefined } // req and res are undefined in getStaticProps context

  /**
   * Build a lightened next-i18next SSR config with no translation in the page props json files
   * to make them a little as possible
   * #loadTranslations
   */
  const _nextI18Next: TI18nextSSRConfig['_nextI18Next'] = {
    // We do not fetch any translation here (see the empty namespace array in serverSideTranslations)
    ...(await serverSideTranslations(locale, [], nextI18nextConfig))?._nextI18Next,
    // We still declare the namespaces we need in _nextI18Next.ns:
    // it will be used in _document to inject the translations in the html pages
    // [...new Set([...])] allows to get only distincts values
    ns: [...new Set([...(namespaces || []), ...COMMON_NAMESPACES])],
  }

  return {
    userAgent: req?.headers['user-agent'],
    locale,
    statusCode,
    _nextI18Next,
  }
}

export type TGenericContext<Params extends ParsedUrlQuery = TAnyProps> =
  | TWrappedServeSideContext<Params>
  | TWrappedStaticContext<Params>
export type TTitleGetter<Context extends TGenericContext = TGenericContext> = string | ((context: Context) => TTitle)

export const dispatchTitle = <Context extends TGenericContext>(
  titleGetter: TTitleGetter<Context>,
  context: Context,
) => {
  const title = typeof titleGetter === 'function' ? titleGetter(context) : titleGetter
  context.store.dispatch(setTitle(title))
}

export const recordPageMetrics = (method: 'getStaticProps' | 'getServerSideProps', pathname: PAGE, locale: TLocale) => {
  // We make a call to the frontend api running on 127.0.0.1:3000
  fetch(`http://127.0.0.1:3000${API_PATHNAME.METRICS_BUILD_PAGE}?method=${method}&pathname=${pathname}&lang=${locale}`)
}
